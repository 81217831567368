import {Checkout} from '@wix/ambassador-checkout/types'
import {Order} from '@wix/ambassador-ep-orders-proxy/http'

export const isFreeCheckout = (checkout: Checkout) => {
  return Number(checkout.priceSummary!.total!.amount) === 0
}

export const isFreeOrder = (order: Order) => {
  return Number(order.priceSummary!.total!.amount) === 0
}
