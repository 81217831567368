import {list} from '@wix/ambassador-cashier-pay-v2-payment-method/http'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const getPaymentMethods = async (flowAPI: ControllerFlowAPI) => {
  const appDefinitionId = flowAPI.controllerConfig.appParams.appDefinitionId
  const instanceId = flowAPI.controllerConfig.appParams.instanceId

  const {data} = await flowAPI.httpClient.request(
    list({accountId: `${appDefinitionId}:${instanceId}`, supportSetupFutureUsages: true}),
  )

  return data?.paymentMethods
}
