import {CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createEventHandler} from '@wix/tpa-settings'

import {isTemplateView} from '../../utils/flowAPI'
import {EXPERIMENTS, SettingsEvents, SettingsEventsValues} from '../../utils/constants'
import {generateBreadcrumbsSD} from '../../utils/getBreadcrumbsConfig'

import {initReservationDetailsStorage, mockReservationDetailsStorage} from './storage'

const createController: CreateControllerFn = async ({flowAPI, controllerConfig}) => {
  const publicData = controllerConfig.config.publicData.COMPONENT || {}
  const handler = createEventHandler<SettingsEventsValues>(publicData)
  const siteStructure = await controllerConfig.wixCodeApi.site.getSiteStructure({
    includePageId: true,
  })

  const experiments = flowAPI.experiments
  const isSeoIntegrationEnabled = experiments.enabled(EXPERIMENTS.isSeoIntegrationEnabled)

  return {
    async pageReady() {
      const isEditor = flowAPI.environment.isEditor
      const isPreview = flowAPI.environment.isPreview
      const isTemplate = isTemplateView(flowAPI)

      handler.on(SettingsEvents.APPROVAL_TEXT_SETTINGS, (value) => {
        flowAPI.controllerConfig.setProps({approvalTextEditorState: value})
      })

      if (isEditor || isPreview || isTemplate) {
        const mockedStorage = await mockReservationDetailsStorage(flowAPI, siteStructure)
        flowAPI.controllerConfig.setProps(mockedStorage)
      } else {
        const storage = initReservationDetailsStorage(flowAPI, siteStructure)
        flowAPI.controllerConfig.setProps(storage)

        const breadCrumbsSD = generateBreadcrumbsSD(storage.breadcrumbsConfig)
        if (isSeoIntegrationEnabled) {
          flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
            itemType: 'BREADCRUMBS_COMPONENT',
            itemData: {items: breadCrumbsSD.itemListElement},
          })
        } else {
          await flowAPI.controllerConfig.wixCodeApi.seo.setStructuredData([breadCrumbsSD])
        }

        if (storage.isReservationIdQueryParamValid) {
          await Promise.all([
            storage.getReservation(storage.reservationIdQueryParam!),
            storage.getReservationLocations(),
            storage.getPaymentMethods(),
          ])
        } else if (storage.isReservationDataQueryParamsValid) {
          await Promise.all([storage.getReservationLocations(), storage.getPaymentMethods()])
        } else {
          storage.goToNewReservation()
        }
      }
    },
    updateConfig(_, config) {
      handler.notify(config.publicData.COMPONENT || {})
    },
  }
}

export default createController
