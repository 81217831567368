import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {Order} from '@wix/ambassador-ep-orders-proxy/http'

import {APP_DEF_ID} from './constants'

export const trackTableReservedEvent = (
  flowAPI: ControllerFlowAPI,
  reservationId: string,
  reservee?: Reservee,
) => {
  flowAPI.controllerConfig.wixCodeApi.window.trackEvent('CustomEvent', {
    appDefId: APP_DEF_ID,
    id: reservationId,
    // @ts-expect-error
    event: 'tableReserved',
    eventCategory: 'leads',
    eventAction: 'reservation',
    userData: {
      first_name: reservee?.firstName,
      last_name: reservee?.lastName,
      email: reservee?.email,
      phone: reservee?.phone,
    },
  })
}

export const trackPurchaseEvent = (
  flowAPI: ControllerFlowAPI,
  reservationId: string,
  order: Order,
) => {
  const contents = order.lineItems?.map((lineItem) => ({
    id: lineItem.id,
    name: lineItem.productName,
    quantity: lineItem.quantity,
    price: lineItem.price?.amount,
  }))

  flowAPI.controllerConfig.wixCodeApi.window.trackEvent('Purchase', {
    appDefId: APP_DEF_ID,
    id: reservationId,
    origin: 'Table Reservations',
    // @ts-expect-error
    orderId: order.id,
    currency: order.currency,
    revenue: order.priceSummary?.totalPrice?.amount,
    contents,
    userData: {
      email: order.buyerInfo?.email,
      phone: order.billingInfo?.contactDetails?.phone,
      first_name: order.billingInfo?.contactDetails?.firstName,
      last_name: order.billingInfo?.contactDetails?.lastName,
      address: order.billingInfo?.address?.addressLine,
      city: order.billingInfo?.address?.city,
      region: order.billingInfo?.address?.subdivision,
      postal_code: order.billingInfo?.address?.postalCode,
      country: order.billingInfo?.address?.country,
    },
  })
}
